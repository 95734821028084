import React from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/main.sass";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <>
      <div className="container-fluid p-0">
        <div
          className="row no-gutters justify-content-center"
          style={{ overflow: "hidden" }}
        >
          <Header />
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
