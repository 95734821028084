import React from "react";
import "../styles/footer.sass";
import footer_logo from "../images/growcards_logo.svg";

const Footer = () => (
  <>
    <div className="footer col-xl-10 col-md-11 col-11 no-gutters">
      <div className="footer-part col-xl-3 col-md-6 col-6 order-md-1 order-1 d-none d-sm-block">
        <div className="footer-part-img">
          <img src={footer_logo} alt="" />
        </div>
        <div className="footer-part-links copyright">
          <div className="footer-link">© 2020. Все права защищены</div>
          <div className="footer-link">
            Powered by{" "}
            <a
              className="footer-link"
              href="https://growire.org"
              target="_blank"
            >
              Growire
            </a>{" "}
            with 💜
          </div>
        </div>
      </div>

      <div className="footer-part col-xl-3 col-md-6 col-6 order-md-2 order-3">
        <div className="col-xl-8 p-0">
          <div className="footer-part-title">Полезные ссылки</div>
          <div className="footer-part-links">
            <a
              href="https://docs.growcards.ru"
              target="_blank"
              className="footer-link"
            >
              База знаний
            </a>
            <a href="" className="footer-link">
              Условия использования
            </a>
          </div>
        </div>
      </div>

      <div className="footer-part col-xl-3 col-md-6 col-6 order-md-3 order-2">
        <div className="col-xl-8 p-0">
          <div className="footer-part-title">GrowCards</div>
          <div className="footer-part-links">
            <a href="#features" className="footer-link">
              Возможности карт
            </a>
            <a href="#functions" className="footer-link">
              Принцип работы
            </a>
            <a href="#subscriptions" className="footer-link">
              Тарифный план
            </a>
            <a
              href="https://app.growcards.ru"
              target="_blank"
              className="footer-link"
            >
              Вход
            </a>
          </div>
        </div>
      </div>

      <div className="footer-part col-xl-3 col-md-6 col-6 order-md-4 order-4">
        <div className="col-xl-8 p-0">
          <div className="footer-part-title">Социальные сети</div>
          <div className="footer-part-links">
            <a
              href="https://twitter.com/growcardsru"
              target="_blank"
              className="footer-link"
            >
              Twitter
            </a>
            <a
              href="https://teleg.run/growcards"
              target="_blank"
              className="footer-link"
            >
              Telegram
            </a>
            <a
              href="https://instagram.com/grwcrds"
              target="_blank"
              className="footer-link"
            >
              Instagram
            </a>
            <a
              href="https://vk.com/growcards"
              target="_blank"
              className="footer-link"
            >
              VK
            </a>
          </div>
        </div>
      </div>

      <div className="footer-part-phone-img d-block d-sm-none">
        <div className="footer-part-img">
          <img src={footer_logo} alt="" />
        </div>
      </div>
      <div className="footer-part-phone-copyright col-12 d-flex d-sm-none ">
        <div className="footer-part-links copyright">
          <div className="footer-link">© 2020. Все права защищены</div>
          <div className="footer-link">
            Powered by{" "}
            <a
              className="footer-link"
              href="https://growire.org"
              target="_blank"
            >
              Growire
            </a>{" "}
            with 💜
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Footer;
