import React from "react";
import "../styles/header.sass";
import header_logo from "../images/growcards_logo.svg";
import { Link } from "gatsby";

const Header = () => (
  <>
    <div className="tablet-header col-12 no-gutters">
      <div className="tablet-header-img col-11">
        <img src={header_logo} alt="" />
      </div>
    </div>

    <div className="header col-xl-10 no-gutters">
      <div className="header-img">
        <img src={header_logo} alt="" />
      </div>

      <div className="header-content col-md-8">
        <Link to={"#features"} className="header-content-tab">
          Возможности
        </Link>
        <Link to={"#spheres"} className="header-content-tab">
          Сферы применения
        </Link>
        <Link to={"#subscriptions"} className="header-content-tab">
          Тарифы
        </Link>
        <a href={"https://docs.growcards.ru"} className="header-content-tab">
          База знаний
        </a>
        <a
          href="https://app.growcards.ru"
          target="_blank"
          className="button-light"
        >
          Вход
        </a>
      </div>
    </div>
  </>
);

export default Header;
